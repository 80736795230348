import "./header_component.css";

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static values = {
    customerInitials: String,
    customerLogo: String,
  };

  connect() {
    this.visible = true;
    this.sendStateToWebView();
  }

  sendStateToWebView() {
    if (!window.FlutterBridge) return;

    const state = this.headerState;
    window.FlutterBridge.sendMessage("HeaderComponent", state);
  }

  get headerState() {
    const $navRoot = this.element.querySelector(".c-nav-root");
    if (!$navRoot) {
      console.error("[Bridge][HeaderComponent] Missing $navRoot");
      return {};
    }
    const $navItemLinks = [...$navRoot.querySelectorAll("a")];
    const $navItemIcons = [
      ...$navRoot.querySelectorAll(".c-icon-root:first-child use"),
    ];

    const actions = $navItemLinks.map(($item, i) => {
      const $svg = $navItemIcons[i];
      const method = $item.getAttribute("data-turbo-method");
      const behavior = $item.getAttribute("data-mobile-behavior");

      return {
        href: $item.getAttribute("href") ?? null,
        text: $item.textContent,
        method: method ?? null,
        behavior: behavior ?? null,
        // ie: substring of `#c-icon-add-user` => `add-user`
        icon: $svg.getAttribute("xlink:href").substring(8),
      };
    });

    const $title = this.element.querySelector(".c-header-content-small");
    const title = $title.textContent ?? null;

    const $rightAction = this.element.querySelector(
      ".c-header-actions .c-button-root"
    );
    const rightAction = $rightAction.href ?? null;

    return {
      visible: this.visible,
      title,
      rightAction,
      customerInitials: this.customerInitialsValue ?? null,
      customerLogo: this.customerLogoValue ?? null,
      actions,
    };
  }
}
