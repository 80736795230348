/**
 *
 * @param {Blob} blob
 * @returns Promise<string> a Base64 encoded string
 */
export function blobToBase64(blob) {
  console.debug("blobToBase64", blob);
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64data = reader.result.split(",")[1];
      resolve(base64data);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}
