import "./menu_component.css";

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["item"];

  connect() {
    this.visible = true;
    this.sendStateToWebView();
  }

  sendStateToWebView() {
    if (!window.FlutterBridge) return;

    window.FlutterBridge.sendMessage("MenuComponent", this.menuState);
  }

  get menuState() {
    return {
      items: this.itemTargets.map((i) => {
        return {
          text: i.innerText,
          url: i.href,
          active: i.ariaCurrent == "true",
          icon: i.dataset.icon,
        };
      }),
      visible: this.visible,
    };
  }
}
