import { Controller } from "@hotwired/stimulus";
import { blobToBase64 } from "../utils";
export default class extends Controller {
  static values = {
    fallback: String,
    accept: String,
  };
  connect() {
    console.debug("[Bridge] connected");

    if (!window.FlutterBridge) {
      return;
    }
  }

  // Events
  /**
   * Override onSubmit event for Flutter Bridge
   * @param {SubmitEvent} e
   * @returns {boolean}
   */
  onSubmit(e) {
    if (window.FlutterBridge) {
      console.debug("[Bridge] onSubmit", e);

      e.preventDefault();
      this.downloadAsFormSubmit(e);
      return false;
    }
  }

  // Actions
  /**
   * Downloads the file from the href of the currentTarget
   * @param {Event} e
   * @returns {boolean}
   */
  async download(e) {
    if (!window.FlutterBridge) {
      return;
    }
    e.preventDefault();
    console.debug("[Bridge] download", e.currentTarget);

    // Get the file extension from event.currentTarget.href
    const filenameExtension = e.currentTarget.href.split(".").pop();
    const fallbackFilename =
      this.fallbackValue ?? `download.${filenameExtension}`;

    const response = await fetch(e.currentTarget.href);
    if (!response.ok) {
      console.error("[Bridge] download failed", e.currentTarget.href, response);
      return false;
    }

    const blob = await response.blob();
    const filename = this.getFilename(response, fallbackFilename);
    console.debug("[Bridge] Retrieved blob", filename);

    blobToBase64(blob).then((base64) => {
      window.flutter_inappwebview.callHandler(
        "downloadBase64",
        filename,
        base64
      );
    });
    return false;
  }

  /**
   * Creates a request based on a form and returns the file
   * @param {SubmitEvent} e
   */
  async downloadAsFormSubmit(e) {
    e.preventDefault();
    console.debug("[Bridge] downloadAsFormSubmit", e);
    let headers = {};
    if (this.acceptValue) {
      headers = {
        Accept: this.acceptValue,
      };
    }

    const response = await fetch(e.target.action, {
      method: e.target.method ?? "POST",
      body: new URLSearchParams(new FormData(e.target)),
      headers: headers,
    });

    if (!response.ok) {
      console.error("[Bridge] download failed", e.target.action, response);
      return false;
    }
    const blob = await response.blob();

    const filename = this.getFilename(response);
    console.debug("[Bridge] Retrieved blob", filename);

    blobToBase64(blob).then((base64) => {
      window.flutter_inappwebview.callHandler(
        "downloadBase64",
        filename,
        base64
      );
    });
  }

  // Utilities
  /**
   * Returns the filename from either the Content-Disposition header or the fallback name
   * @param {Response} response
   * @param {String} fallback
   * @returns String
   */
  getFilename(response, fallback = "download") {
    const header = response.headers.get("Content-Disposition");
    const match = header?.match(/filename="?([^"]+)"?/);
    return match ? match[1] : fallback;
  }
}
